import {
  getClassificationTagV1,
  getJDV2_JOBDA,
  getJDV2_JOBFLEX,
  getPositionListForResumeUpdateV1_JOBFLEX,
  getSettingV1,
  postListV1_JOBDA,
  postListV1_JOBFLEX,
} from '@api/position';
import { IGetJDV2Response } from '@interface/position/getJD';
import { IPostListV1Request, IPostListV1Response } from '@interface/position/getList';
import { IGetPositionListForResumeUpdateV1Response } from '@interface/position/getPositionListForResumeUpdate';
import { IGetSettingV1Response } from '@interface/position/getSetting';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { SOLUTION, SolutionType } from 'ui/constants/solution/solution.constant';
import { IGetClassificationTagV1Response } from 'ui/interface/position/getClassificationTagV1';
import { TClassificationType } from 'ui/interface/recruit/IRecruitSetting';

export const PositionQueryKey = {
  all: ['position'] as const,
  list: {
    all: () => [...PositionQueryKey.all, 'list'],
    jd: {
      all: () => [...PositionQueryKey.list.all(), 'jd'],
      status: (solution: SolutionType) => [...PositionQueryKey.list.jd.all(), 'status', solution],
    },
    resumeUpdate: (queryParam: string) => [...PositionQueryKey.list.all(), 'resumeUpdate', queryParam],
  },
  setting: {
    all: () => [...PositionQueryKey.all, 'setting'],
  },
  classificationTag: (type: TClassificationSearchType = 'ALL') => [...PositionQueryKey.all, 'classificationTag', type],
};

export const useQueryGetSettingV1 = (enabled = true): UseQueryResult<IGetSettingV1Response, AxiosError> => {
  return useQuery({
    queryKey: PositionQueryKey.setting.all(),
    queryFn: getSettingV1,
    staleTime: 300000,
    enabled: enabled,
  });
};

// 공고설정: 포지션 분류태그 리스트 조회
export type TClassificationSearchType = TClassificationType | 'ALL';
export const useQueryGetClassificationTagV1 = (
  type: TClassificationSearchType = 'ALL',
  enabled = true
): UseQueryResult<IGetClassificationTagV1Response, AxiosError> => {
  return useQuery({
    queryKey: PositionQueryKey.classificationTag(type),
    queryFn: () => getClassificationTagV1(type),
    staleTime: 300000,
    enabled: enabled,
  });
};

// 특정 페이지, 필터를 기준으로 채용공고 리스트 조회
export const useMutationPostListV1_JOBDA = (): UseMutationResult<
  IPostListV1Response,
  AxiosError,
  IPostListV1Request
> => {
  return useMutation({
    mutationFn: postListV1_JOBDA,
  });
};

export const useMutationPostListV1_JOBFLEX = (): UseMutationResult<
  IPostListV1Response,
  AxiosError,
  IPostListV1Request
> => {
  return useMutation({
    mutationFn: postListV1_JOBFLEX,
  });
};

export const useMutationPostListV1 = {
  [SOLUTION.JOBDA]: useMutationPostListV1_JOBDA,
  [SOLUTION.JOBFLEX]: useMutationPostListV1_JOBFLEX,
};
// export const useMutationPostListV1 = (): UseMutationResult<IPostListV1Response, AxiosError, IPostListV1Request> => {
//   return useMutation({
//     mutationFn: postListV1,
//   });
// };

interface IUseQueryGetJDV2Param {
  positionSn: number;
}

export const useQueryGetJDV2_JOBDA = ({
  positionSn,
}: IUseQueryGetJDV2Param): UseQueryResult<IGetJDV2Response, AxiosError> => {
  return useQuery({
    queryKey: [...PositionQueryKey.list.jd.all()],
    queryFn: () =>
      getJDV2_JOBDA({
        positionSn,
      }),
    keepPreviousData: true,
  });
};

export const useQueryGetJDV2_JOBFLEX = ({
  positionSn,
}: IUseQueryGetJDV2Param): UseQueryResult<IGetJDV2Response, AxiosError> => {
  return useQuery({
    queryKey: [...PositionQueryKey.list.jd.all()],
    queryFn: () =>
      getJDV2_JOBFLEX({
        positionSn,
      }),
    keepPreviousData: true,
  });
};

export const useQueryGetJD = {
  [SOLUTION.JOBDA]: useQueryGetJDV2_JOBDA,
  [SOLUTION.JOBFLEX]: useQueryGetJDV2_JOBFLEX,
};

export const useQueryGetPositionListForResumeUpdateV1 = (
  queryParam: string
): UseQueryResult<IGetPositionListForResumeUpdateV1Response, AxiosError> => {
  return useQuery({
    queryKey: [...PositionQueryKey.list.resumeUpdate(queryParam)],
    queryFn: () => getPositionListForResumeUpdateV1_JOBFLEX(queryParam),
    keepPreviousData: true,
  });
};
