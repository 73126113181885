import React from 'react';

import classnames from 'classnames/bind';
import styles from './BlindByViewMode.module.scss';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { LANGUAGE, Language } from '../../constants/language';

const cx = classnames.bind(styles);

function BlindForMobile() {
  const { get } = useSearchParams() as ReadonlyURLSearchParams;
  const languageType = (get('languageType') as Language) || LANGUAGE.KOR;

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <img className={cx('icon')} src={'/img/common/icon_smartphone_sms_mono.png'} alt={''} />
        <h3 className={cx('text', 'main')}>
          {languageType === LANGUAGE.KOR ? (
            <>
              해당 페이지는
              <br />
              모바일에서 확인할 수 없어요.
            </>
          ) : (
            <>
              Not accessible on
              <br />
              mobile devices
            </>
          )}
        </h3>
        <p className={cx('text', 'sub')}>
          {languageType === LANGUAGE.KOR ? (
            <>
              PC환경에서 접속해주세요.
              <br />
              PC환경이라면 화면 크기를 키우거나 화면 비율을 줄여주세요.
            </>
          ) : (
            'Please use a PC to access this page.'
          )}
        </p>
      </div>
    </div>
  );
}

export default BlindForMobile;
