'use client';

import { FC } from 'react';
import classNames from 'classnames/bind';
import usePaginationEvent from './hooks/usePaginationEvent';
import * as React from 'react';
import Icon from '../icon/Icon';
import { range } from '../../util/array';
import PaginationVO from '../../model/vo/PaginationVO';
import IconButtonDeprecated from '../IconButton/IconButtonDeprecated';

import styles from './Pagination.module.scss';
import { COLORS } from '../../constants/colors';
import DropdownWithOnClick from '../dropdownWithOnClick/Dropdown';
const cx = classNames.bind(styles);

export interface IProps {
  page: PaginationVO;
  beforeCountWord?: string;
  afterCountWord?: string;
  pageHandler(pagination: PaginationVO): void;
  showOnlyPagination?: boolean;
  showCount?: boolean;
  showDropdown?: boolean;
}

const Pagination: FC<IProps> = (IProps) => {
  const {
    page: { totalCount, pageSize },
    beforeCountWord,
    afterCountWord,
    showOnlyPagination,
    showCount = true,
    showDropdown = true,
  } = IProps;
  const { next, prev, pageSizeHandler, possibleNext, possiblePrev, drawPage } = usePaginationEvent(IProps);

  return (
    <div className={cx('container')}>
      <div className={cx('left')}>
        {!showOnlyPagination && showCount && (
          <>
            <Icon name={'list_view_line'} size={24} color={COLORS.gray700} />
            {beforeCountWord ?? '총'} {totalCount}
            {afterCountWord ?? '개'}
          </>
        )}
      </div>
      <div className={cx('middle')}>
        {
          <IconButtonDeprecated
            icon={'back_line'}
            className={cx('prev', 'btn', possiblePrev() && 'possible')}
            disabled={!possiblePrev()}
            onClick={prev}
          />
        }
        <ol className={cx('pagination')}>{drawPage()}</ol>
        {
          <IconButtonDeprecated
            icon={'next_line'}
            className={cx('next', 'btn', possibleNext() && 'possible')}
            disabled={!possibleNext()}
            color={COLORS.gray700}
            onClick={next}
          />
        }
      </div>
      <div className={cx('right')}>
        {!showOnlyPagination && showDropdown && (
          <>
            <span className={cx('margin')}>페이지 행</span>
            <DropdownWithOnClick
              className={cx('select')}
              size={'sm'}
              options={range(5).map((v) => ({
                name: `${(v + 1) * 10}`,
                value: `${(v + 1) * 10}`,
              }))}
              value={`${pageSize}`}
              onChange={(value) => pageSizeHandler({ label: value, value })}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Pagination;
