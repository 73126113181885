import { TAnnouncementType } from '../../constants/common';

interface IDateBlockProps {
  dday: number;
  startDateTime: string;
  endDateTime: string;
  announcementType?: TAnnouncementType;
}

const useRecruitDate = () => {
  const findDateBlockText = ({ dday, endDateTime, announcementType }: IDateBlockProps) => {
    if (announcementType === 'ALWAYS') return '상시채용';
    const now = new Date();
    if (dday === null) return '';
    if (new Date(endDateTime) < now) return '접수 마감';
    if (dday === 0) return 'D-Day';
    if (dday !== null) return `D-${dday}`;
    return '';
  };

  return {
    findDateBlockText,
  };
};

export default useRecruitDate;
