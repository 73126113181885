import { ReactNode, useCallback } from 'react';
import classNames from 'classnames/bind';

import styles from '../Pagination.module.scss';
import { IProps } from '../Pagination';
import { SingleValue } from 'react-select';
import PaginationVO from '../../../model/vo/PaginationVO';
const cx = classNames.bind(styles);

const usePaginationEvent = ({ page: { totalPages, totalCount, pageSize, page }, pageHandler }: IProps) => {
  const possiblePrev = useCallback(() => {
    return page !== 1;
  }, [totalPages, totalCount, pageSize, page]);

  const possibleNext = useCallback(() => {
    return page !== totalPages;
  }, [totalPages, totalCount, pageSize, page]);

  const drawPage = useCallback(() => {
    let pageList: ReactNode[] = [];
    const minScope = page <= 2 ? 1 : page + 2 > totalPages ? (totalPages - 5 < 1 ? 1 : totalPages - 4) : page - 2;
    const maxScope = page <= 2 ? (totalPages < 5 ? totalPages : 5) : page + 2 > totalPages ? totalPages : page + 2;
    for (let i = minScope; i <= maxScope; i++) {
      pageList = [
        ...pageList,
        <li
          key={i}
          className={cx(i === page && 'active')}
          onClick={() => {
            pageHandler(new PaginationVO({ page: i, totalCount, pageSize, totalPages }));
          }}
        >
          {i}
        </li>,
      ];
    }
    return pageList;
  }, [totalPages, totalCount, pageSize, page]);

  const next = useCallback(
    () => pageHandler(new PaginationVO({ page: page + 1, totalCount, pageSize, totalPages })),
    [totalPages, totalCount, pageSize, page]
  );

  const prev = useCallback(
    () => pageHandler(new PaginationVO({ page: page - 1, totalCount, pageSize, totalPages })),
    [totalPages, totalCount, pageSize, page]
  );

  const pageSizeHandler = useCallback(
    (newValue: SingleValue<{ label: string; value: string }>) =>
      newValue &&
      pageHandler(
        new PaginationVO({
          totalPages,
          totalCount,
          pageSize: Number(newValue.value),
          page: 1,
        })
      ),
    [totalPages, totalCount, pageSize, page]
  );

  return {
    next,
    prev,
    possibleNext,
    possiblePrev,
    drawPage,
    pageSizeHandler,
  };
};

export default usePaginationEvent;
