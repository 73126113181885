import { FC, useState } from 'react';
import classnames from 'classnames/bind';
import { ViewModeType } from 'ui/interface/common/ViewMode';
import QnAPage from 'ui/component/qna/QnAPage';
import {
  useQueryGetAgreementLetterContentV1,
  useQueryGetPositionListV1,
  useQueryGetRecruitmentField,
} from '@queries/qna';
import style from './QnAWrapper.module.scss';
import { postQnAJOBDASendV1, postQnASendV1 } from '@api/qna';
import useToast from 'ui/context/toast/useToast';
import useAlert from 'ui/hook/useAlert';
import { SOLUTION_TYPE } from 'ui/util/solution';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { IQnAJOBDAForm } from 'ui/interface/qna';

const cx = classnames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  isPreview?: boolean;
}

const QnAWrapper: FC<IProps> = ({ viewMode, isPreview = false }) => {
  const { setFailToast } = useToast();
  const { setAlert } = useAlert();

  const [positionSn, setPositionSn] = useState<number | null>(null);
  const [positionRecruitmentFieldGroupSn, setPositionRecruitmentFieldGroupSn] = useState<number | null>(null);

  const { data: positionListData } = useQueryGetPositionListV1(isPreview);
  const { data: recruitmentFieldData } = useQueryGetRecruitmentField(isPreview, positionSn);
  const { data: agreementLetterContents, isError } = useQueryGetAgreementLetterContentV1(
    isPreview,
    positionListData?.agreementLetterSn
  );

  return (
    <div className={cx('container')}>
      <QnAPage
        viewMode={viewMode}
        positionList={positionListData?.positionList ?? []}
        recruitmentFieldList={recruitmentFieldData?.groupList ?? []}
        agreement={{
          agreementLetterContents: agreementLetterContents?.contents ?? '',
          isError,
        }}
        positionSn={positionSn}
        setPositionSn={setPositionSn}
        positionRecruitmentFieldGroupSn={positionRecruitmentFieldGroupSn}
        setPositionRecruitmentFieldGroupSn={setPositionRecruitmentFieldGroupSn}
        onSubmit={async (request) => {
          const PostQnASendV1 = {
            [SOLUTION.JOBFLEX]: () =>
              postQnASendV1({
                ...request,
                agreementLetterSn: positionListData?.agreementLetterSn ?? 0,
              }),
            [SOLUTION.JOBDA]: () => postQnAJOBDASendV1(request as IQnAJOBDAForm),
          };

          try {
            await PostQnASendV1[SOLUTION_TYPE]();

            setAlert({
              title: '문의가 정상적으로 등록되었어요!',
              description: '문의 내용 확인 후, 순차적으로 이메일 답변드리도록 하겠습니다.',
            });
          } catch (e) {
            setFailToast();
            console.error(e);
          }
        }}
      />
    </div>
  );
};

export default QnAWrapper;
