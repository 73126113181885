import style from './QnAPage.module.scss';
import classnames from 'classnames/bind';
import Paragraph from '../../common/paragraph/Paragraph';
import DropdownWithOnClick from '../../common/dropdownWithOnClick/Dropdown';
import { PositionList } from '../../interface/qna';
import { FC } from 'react';
import { HasOption } from '../../interface/common/hasOption';
import { LANGUAGE } from '../../constants/language';

const cx = classnames.bind(style);

interface Props {
  isPreview?: boolean;
  positionList?: PositionList[];
  positionSn?: number | null;
  setPositionSn?: (positionSn: number | null) => void;
}
const NORMAL_TYPE = {
  name: '일반문의',
  value: 'null',
};

const QnAPositionJobda: FC<Props & HasOption> = ({ isPreview, positionSn, positionList, setPositionSn, options }) => {
  const languageType = options && options['languageType'];

  const getFilterValue = () => {
    const { positionSn: filterSn, positionName } = (positionList ?? []).filter(
      ({ positionSn: sn }) => positionSn === sn
    )[0]!;

    return { name: positionName, value: `${filterSn}` };
  };

  const NORMAL_TYPE = {
    name: languageType === LANGUAGE.KOR ? '일반문의' : 'General Inquiries',
    value: 'null',
  };

  return (
    <div className={cx('form-grid-2')}>
      <div className={cx('form-grid-1')}>
        <Paragraph className={cx('form-label')} variant={'B2'} bold>
          <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '포지션명' : 'Position'}</span>
        </Paragraph>
        <DropdownWithOnClick
          className={cx('select')}
          size={'lg'}
          options={[
            NORMAL_TYPE,
            ...positionList!.map(({ positionSn, positionName }: PositionList) => ({
              name: <span className={cx('option-label')}>{positionName}</span>,
              value: `${positionSn}`,
            })),
          ]}
          value={positionSn ? getFilterValue().value : NORMAL_TYPE.value}
          onChange={(value) => {
            if (!setPositionSn) return;

            if (value === 'null') {
              setPositionSn(null);
            } else {
              setPositionSn(Number(value));
            }
          }}
          disabled={isPreview}
        />
      </div>
    </div>
  );
};

export default QnAPositionJobda;
