import { IQnAJOBDAForm, IQnAJOBFLEXForm, PositionList, RecruitmentField } from 'ui/interface/qna';
import { recruiterAxios } from 'ui/api/requestClient/recruiter';
import { APPS, AppType } from 'ui/constants/app.constant';
// TODO editor에서도 qna api를 사용하는지 확인해서 분기처리 필요
/*******************************************
 *          JOBDA MATCHING 포지션명          *
 *******************************************/
export const getJOBDAPositionListV1 = async (): Promise<{
  positionList: PositionList[];
  agreementLetterSn: number;
}> => {
  const { data } = await recruiterAxios.get(`/qna/v1/jobda`);
  return data;
};

export const postQnAJOBDASendV1 = async (request: IQnAJOBDAForm) => {
  await recruiterAxios.post('/qna/v1/jobda', { ...request });
};

/*******************************************
 *            JOBFLEX 공고명/채용분야          *
 *******************************************/
export const getPositionListV1 = async (): Promise<{ positionList: PositionList[]; agreementLetterSn: number }> => {
  const { data } = await recruiterAxios.get(`/qna/v1`);
  return data;
};

/** 공고에 따른 채용분야 조회 */
export const getRecruitmentField = async (positionSn: number): Promise<{ groupList: RecruitmentField[] }> => {
  const { data } = await recruiterAxios.get(`/qna/v1/recruitment-field?positionSn=${positionSn}`);
  return data;
};

/** QNA 선택한 동의문에 대한 개인정보 수집 이용 동의문 조회 */
export const getAgreementLetterContentV1 = async (
  agreementLetterSn: number
): Promise<{ contents: string; title: string }> => {
  const { data } = await recruiterAxios.get(`/qna/v1/private-contents?agreementLetterSn=${agreementLetterSn}`);
  return data;
};

/** 동의문 전송 */
export const postQnASendV1 = async (request: IQnAJOBFLEXForm) => {
  await recruiterAxios.post('/qna/v1', request);
};
