import { useContext } from 'react';
import GlobalContext from '../context/GlobalContext';

const useAlert = () => {
  const {
    alert: { alert, setAlert },
  } = useContext(GlobalContext);

  return { alert, setAlert };
};

export default useAlert;
