class PaginationVO {
  private readonly _page: number;
  private readonly _pageSize: number;
  private readonly _totalCount?: number;
  private readonly _totalPages?: number;

  constructor(page: { page: number; pageSize: number; totalCount?: number; totalPages?: number }) {
    this._page = page.page;
    this._pageSize = page.pageSize;
    this._totalCount = page.totalCount;
    this._totalPages = page.totalPages;
  }

  get totalPages(): number {
    return this._totalPages ?? 1;
  }

  get page(): number {
    return this._page;
  }

  get pageSize(): number {
    return this._pageSize;
  }

  get totalCount(): number {
    return this._totalCount ?? 0;
  }
}

export default PaginationVO;
