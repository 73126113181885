import React, { ComponentPropsWithoutRef, FC, forwardRef, Ref } from 'react';
import classNames from 'classnames/bind';

import styles from './IconButtonDeprecated.module.scss';
import Icon, { IconTypes } from '../icon/Icon';
import { COLORS } from '../../constants/colors';

const cx = classNames.bind(styles);

interface IProps extends ComponentPropsWithoutRef<'button'> {
  ref?: Ref<HTMLButtonElement>;
  size?: 18 | 24 | 32 | 48;
  fill?: string;
  variant?: 'default' | 'primary' | 'circle' | 'card' | 'circle-line';
  shape?: 'default' | 'circle';
  icon: IconTypes;
  border?: boolean;
}

const IconButtonDeprecated: FC<IProps> = forwardRef(function IconButton(
  { size = 18, shape = 'default', variant = 'default', border = false, fill = COLORS.gray500, ...props },
  ref
) {
  return (
    <button
      {...props}
      ref={ref}
      type={'button'}
      className={cx('icon-button', shape, `border-${border}`, props.className, `variant-${variant}`)}
    >
      <Icon name={props.icon} size={size} color={fill} />
    </button>
  );
});

export default IconButtonDeprecated;
