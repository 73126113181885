import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getAgreementLetterContentV1, getJOBDAPositionListV1, getPositionListV1, getRecruitmentField } from '@api/qna';
import { PositionList, RecruitmentField } from 'ui/interface/qna';
import { APPS, AppType } from 'ui/constants/app.constant';
import { SOLUTION } from 'ui/constants/solution/solution.constant';
import { SOLUTION_TYPE } from 'ui/util/solution';

export const QnAQueryKey = {
  all: ['qna'] as const,
  list: () => [...QnAQueryKey.all, 'list'],
  field: () => [...QnAQueryKey.all, 'field'],
  agreement: () => [...QnAQueryKey.all, 'agreement'],
};

export const useQueryGetPositionListV1 = (
  isPreview = true
): UseQueryResult<{ positionList: PositionList[]; agreementLetterSn: number }> => {
  const getPositionList = {
    [SOLUTION.JOBDA]: getJOBDAPositionListV1,
    [SOLUTION.JOBFLEX]: getPositionListV1,
  };

  return useQuery({
    queryKey: QnAQueryKey.list(),
    queryFn: () => getPositionList[SOLUTION_TYPE](),
    enabled: !isPreview,
  });
};

export const useQueryGetRecruitmentField = (
  isPreview = true,
  positionSn: number | null
): UseQueryResult<{ groupList: RecruitmentField[] }> => {
  return useQuery({
    queryKey: [...QnAQueryKey.field(), positionSn],
    queryFn: () => getRecruitmentField(positionSn!),
    enabled: !isPreview && !!positionSn,
  });
};

export const useQueryGetAgreementLetterContentV1 = (
  isPreview: boolean,
  agreementLetterSn?: number
): UseQueryResult<{ title: string; contents: string }> => {
  return useQuery({
    queryKey: [...QnAQueryKey.agreement()],
    queryFn: () => getAgreementLetterContentV1(agreementLetterSn!),
    enabled: !isPreview && !!agreementLetterSn,
  });
};
