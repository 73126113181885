import { useRouter, useSearchParams, usePathname, ReadonlyURLSearchParams } from 'next/navigation';

type UrlValueType = string | number;
const useSearchParamsExtended = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams() as ReadonlyURLSearchParams;

  const urlQueryStringList = new URLSearchParams(Array.from(searchParams.entries()));

  const getParams = (target: string, decode = true): string => {
    const value = urlQueryStringList.get(target) ?? '';
    return decode ? decodeURI(value) : value;
  };

  const setParams = (target: string, _value: UrlValueType, encode = true) => {
    const value = encode ? encodeURI(`${_value}`) : _value;
    urlQueryStringList.set(target, `${value}`);
    const search = urlQueryStringList.toString();
    const query = search ? `?${search}` : '';
    router.push(`${pathname}${query}`);
  };

  const deleteParams = (target: string) => {
    urlQueryStringList.delete(target);
  };

  return { ...searchParams, get: getParams, set: setParams, delete: deleteParams };
};

export default useSearchParamsExtended;
