import { useEffect, useState } from 'react';
import { DEFAULT_PAGE } from '../constants/common';
import useSearchParamsExtended from './useSearchParamsExtended';

interface IProps {
  totalPages?: number;
  getList?: (page: number) => void;
  pageName: string; // ex. articlePage
  refetchDependencies?: unknown[];
}

const usePagination = ({ totalPages, getList, pageName, refetchDependencies = [] }: IProps) => {
  const { get, set } = useSearchParamsExtended();
  const pageNumber = Number(get(pageName) ?? DEFAULT_PAGE);
  const [totalPagesInner, setTotalPagesInner] = useState(1);

  const changeUrlPage = (page: number) => {
    set(pageName, page);
  };

  const validatePage = (page: number) => {
    if (page > totalPagesInner) return totalPagesInner;
    if (page < 1) return 1;
    return page;
  };

  useEffect(() => {
    const validatedPage = validatePage(pageNumber);
    getList?.(validatedPage);
  }, [pageNumber, totalPagesInner, ...refetchDependencies]);

  useEffect(() => {
    if (!totalPages || totalPages === 1) return;
    setTotalPagesInner(totalPages);
  }, [totalPages]);

  return { pageNumber, changeUrlPage };
};

export default usePagination;
