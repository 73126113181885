'use client';

import { useQueryGetPositionListForResumeUpdateV1 } from '@queries/position';
import classnames from 'classnames/bind';
import { ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';
import Icon from 'ui/common/icon/Icon';
import Pagination from 'ui/common/pagination/Pagination';
import Tag from 'ui/common/tag/Tag';
import TextFieldBase from 'ui/common/textfield/TextFieldBase';
import BlindForMobile from 'ui/component/common/BlindByViewMode';
import Divider from 'ui/component/common/Divider';
import NoResult from 'ui/component/common/NoResult';
import { COLORS } from 'ui/constants/colors';
import useRecruitListDate from 'ui/hook/recruit/useRecruitListDate';
import useDebouncedInput from 'ui/hook/useDebouncedInput';
import usePagination from 'ui/hook/usePagination';
import { VIEW_MODE, ViewModeType } from 'ui/interface/common/ViewMode';
import PaginationVO from 'ui/model/vo/PaginationVO';
import { formatDate } from 'ui/util/dayUtil';
import styles from './ResumeUpdatePage.module.scss';
import { LANGUAGE, Language } from 'ui/constants/language';

const cx = classnames.bind(styles);

interface IProps {
  viewMode?: ViewModeType;
  isPreview?: boolean;
}

const DEFAULT_PAGE_SIZE = 6;
const ResumeUpdatePage = ({ viewMode, isPreview }: IProps) => {
  const { get } = useSearchParams() as ReadonlyURLSearchParams;
  const { keywordForShow, handleChangeKeyword, filteredKeyword } = useDebouncedInput();
  const { findDateBlockText } = useRecruitListDate();
  const queryParam = useMemo(() => {
    const queryParam = new URLSearchParams({
      keyword: filteredKeyword,
      page: get('positionPage') ?? '1',
      size: DEFAULT_PAGE_SIZE.toString(),
    });
    return queryParam.toString();
  }, [filteredKeyword, get('positionPage')]);

  const languageType = (get('languageType') as Language) || LANGUAGE.KOR;
  const { data: positionListData } = useQueryGetPositionListForResumeUpdateV1(queryParam);

  const { list, pagination } = positionListData || {};
  const { changeUrlPage } = usePagination({
    pageName: 'positionPage',
    totalPages: positionListData?.pagination.totalPages || 1,
  });

  if (viewMode !== VIEW_MODE.PC) return <BlindForMobile />;

  return (
    <div className={cx('container')}>
      <h2 className={cx('title')}>{languageType === LANGUAGE.KOR ? '지원서 수정' : 'Update Resume'}</h2>
      <p className={cx('description')}>
        {languageType === LANGUAGE.KOR
          ? '지원서를 수정할 공고를 먼저 선택해주세요.'
          : 'Please select the job posting to edit your resume.'}
      </p>

      <div className={cx('search-bar')}>
        <TextFieldBase
          size={'lg'}
          placeholder={languageType === LANGUAGE.KOR ? '공고 검색하기' : 'Search'}
          beforeInput={
            <Icon className={cx('search-icon')} name={'search_line'} color={`${COLORS.gray600}`} size={24} />
          }
          value={keywordForShow}
          onChange={(e) => handleChangeKeyword(e.target.value, () => changeUrlPage(1))}
        />
      </div>

      <ul className={cx('position-list')}>
        {!list || !list.length ? (
          <NoResult title={'진행 중인 공고가 없어요.'} subTitle={'혹시 다른 키워드로 검색해보는 건 어떠세요?'} />
        ) : (
          list.map((position) => {
            const { title, recruitmentType, dday, startDateTime, endDateTime, resumeUpdateUrl } = position;
            const ddayText = findDateBlockText({ dday, startDateTime, endDateTime });
            return (
              <li className={cx('position')} onClick={() => window.open(resumeUpdateUrl, '_blank')}>
                <div className={cx('title-area')}>
                  <div className={cx('title')}>{title}</div>
                  {recruitmentType === 'PERMANENT' && (
                    <Tag className={cx('tag', 'always')} variant={'secondary'} color={'green'} round={false}>
                      {languageType === LANGUAGE.KOR ? '상시 채용' : 'Ongoing'}
                    </Tag>
                  )}
                </div>
                <div className={cx('info-area')}>
                  <Tag className={cx('tag', 'submit')} variant={'primary'} color={'teal'} round={false}>
                    {languageType === LANGUAGE.KOR ? '접수중' : 'Open '}
                  </Tag>
                  {ddayText && <div className={cx('dday')}>{ddayText}</div>}
                  <Divider direction={'column'} size={1} margin={12} customStyle={{ height: '14px' }} />
                  <div className={cx('submit-period')}>
                    {`${formatDate(startDateTime, false)} ~ ${formatDate(endDateTime, true, '영입 종료 시')}`}
                  </div>
                </div>
              </li>
            );
          })
        )}
      </ul>
      <div className={cx('pagination')}>
        <Pagination
          showOnlyPagination
          showDropdown={false}
          pageHandler={({ page }) => changeUrlPage(page)}
          page={
            new PaginationVO({
              pageSize: pagination?.size || DEFAULT_PAGE_SIZE,
              page: pagination?.page || 1,
              totalPages: pagination?.totalPages || 1,
            })
          }
        />
      </div>
    </div>
  );
};

export default ResumeUpdatePage;
