import { useState } from 'react';

const useDebouncedInput = () => {
  const [keywordForShow, setKeywordForShow] = useState('');
  const [filteredKeyword, setFilteredKeyword] = useState('');
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const pattern = /([ㄱ-ㅣ]$)/i;
  const handleChangeKeyword = async (keyword: string, callback?: () => void, debounceTime = 300) => {
    setKeywordForShow(keyword);
    if (timer) clearTimeout(timer);
    setTimer(
      await setTimeout(async () => {
        if (pattern.test(keyword)) return;
        setFilteredKeyword(keyword);
        await callback?.();
      }, debounceTime)
    );
  };

  return { keywordForShow, setKeywordForShow, filteredKeyword, handleChangeKeyword };
};

export default useDebouncedInput;
