import { IGetJDV2Request } from '@interface/position/getJD';
import { IPostListV1Request } from '@interface/position/getList';
import { IGetPositionListForResumeUpdateV1Response } from '@interface/position/getPositionListForResumeUpdate';
import {
  IGetPositionMetaDataV1Request,
  IGetPositionMetaDataV1Response,
} from '@interface/position/getPositionMetaDataV1';
import { TClassificationSearchType } from '@queries/position';
import { axios } from '../RequestClient';

export const getSettingV1 = async () => {
  const { data } = await axios.get('/position/v1/setting');
  return data;
};

// 공고설정: 포지션 분류태그 리스트 조회
export const getClassificationTagV1 = async (type: TClassificationSearchType) => {
  const { data } = await axios.get(`/position/v1/tag/${type}`);
  return data;
};

// TODO:: 두벌관리 분리 예정
export const postListV1 = async ({ pageableRq, filter }: IPostListV1Request) => {
  const query = { pageableRq, filter };
  const { data } = await axios.post(`/position/v1`, query);
  return data;
};

export const postListV1_JOBDA = async ({ pageableRq, filter }: IPostListV1Request) => {
  const { data } = await axios.post(`/position/v1/jobda`, { pageableRq, filter });
  return data;
};

export const postListV1_JOBFLEX = async ({ pageableRq, filter }: IPostListV1Request) => {
  const { data } = await axios.post(`/position/v1/jobflex`, { pageableRq, filter });
  return data;
};

export const getJDV2_JOBDA = async ({ positionSn, prefix }: IGetJDV2Request) => {
  try {
    const { data } = await axios.get(`/position/v2/jobda/${positionSn}`, {
      headers: {
        prefix,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getJDV2_JOBFLEX = async ({ positionSn, prefix }: IGetJDV2Request) => {
  try {
    const { data } = await axios.get(`/position/v2/jobflex/${positionSn}`, {
      headers: {
        prefix,
      },
    });
    return data;
  } catch (e) {
    throw e;
  }
};

export const getPositionMetaDataV1 = async ({
  positionSn,
  prefix,
}: IGetPositionMetaDataV1Request): Promise<IGetPositionMetaDataV1Response> => {
  try {
    const { data } = await axios.get(`/position/v1/${positionSn}/meta-data`, { headers: { prefix } });
    return data;
  } catch (e) {
    return {
      companyName: '',
      positionName: '',
      endDateTime: '',
    };
  }
};

// 지원서 수정: 지원서 수정 URL 포지션 목록 조회
export const getPositionListForResumeUpdateV1_JOBFLEX = async (
  queryParam: string
): Promise<IGetPositionListForResumeUpdateV1Response> => {
  const { data } = await axios.get(`/position/v1/jobflex/resume-update?${queryParam}`);
  return data;
};
