import { COLORS } from 'ui/constants/colors';

interface IProps {
  direction?: 'row' | 'column';
  margin?: number;
  color?: string;
  size?: number;
  customStyle?: { [key: string]: string };
}

const Divider = ({ direction = 'row', margin = 16, color = COLORS.gray300, size = 1, customStyle = {} }: IProps) => {
  const DIVIDER_GROUP = {
    row: (
      <div
        style={{
          width: customStyle.width ?? '100%',
          height: customStyle.height ?? `${size}px`,
          margin: customStyle.margin ?? `${margin}px 0`,
          backgroundColor: customStyle.backgroundColor ?? color,
          ...customStyle,
        }}
      ></div>
    ),
    column: (
      <span
        style={{
          display: 'inline-block',
          width: customStyle.width ?? `${size}px`,
          height: customStyle.height ?? '100%',
          margin: customStyle.margin ?? `0 ${margin}px`,
          backgroundColor: customStyle.backgroundColor ?? color,
          ...customStyle,
        }}
      ></span>
    ),
  };

  return DIVIDER_GROUP[direction];
};

export default Divider;
