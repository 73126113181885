import { FC } from 'react';

import classNames from 'classnames/bind';
import style from './NoResult.module.scss';
import Icon from '../../common/icon/Icon';
import { ViewModeType } from '../../interface/common/ViewMode';
const cx = classNames.bind(style);

interface IProps {
  className?: string;
  title?: string;
  subTitle?: string;
  viewMode?: ViewModeType;
  iconSize?: number;
  padding?: boolean;
}

const NoResult: FC<IProps> = ({ className, title, subTitle, iconSize, padding = true }) => {
  return (
    <div className={cx('none-result', className, { padding })}>
      <Icon name={'bubble_pending'} size={iconSize ?? 104} color={'#CFD1D5'} />
      {title && <h4>{title}</h4>}
      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};

export default NoResult;
