import Paragraph from '../../common/paragraph/Paragraph';
import DropdownWithOnClick from '../../common/dropdownWithOnClick/Dropdown';
import { PositionList, RecruitmentField } from '../../interface/qna';
import style from './QnAPage.module.scss';
import classnames from 'classnames/bind';
import { FC } from 'react';
import { HasOption } from '../../interface/common/hasOption';
import { LANGUAGE } from '../../constants/language';

const cx = classnames.bind(style);

interface Props {
  isPreview?: boolean;
  positionList?: PositionList[];
  positionSn?: number | null;
  setPositionSn?: (positionSn: number | null) => void;
  setPositionRecruitmentFieldGroupSn?: (positionSn: number | null) => void;
  recruitmentFieldList?: RecruitmentField[];
  positionRecruitmentFieldGroupSn?: number | null;
  isRecruitmentFieldError?: boolean;
  setIsRecruitmentFieldError?: (isError: boolean) => void;
}

const QnAPositionJobflex: FC<Props & HasOption> = ({
  isPreview,
  positionSn,
  positionList,
  setPositionRecruitmentFieldGroupSn,
  setPositionSn,
  recruitmentFieldList,
  positionRecruitmentFieldGroupSn,
  options,
  isRecruitmentFieldError,
  setIsRecruitmentFieldError,
}) => {
  const languageType = options && options['languageType'];
  const getFilterValue = () => {
    const { positionSn: filterSn, positionName } = (positionList ?? []).filter(
      ({ positionSn: sn }) => positionSn === sn
    )[0]!;

    return { name: positionName, value: `${filterSn}` };
  };

  const NORMAL_TYPE = {
    name: languageType === LANGUAGE.KOR ? '일반문의' : 'General Inquiries',
    value: 'null',
  };

  return (
    <div className={cx('form-grid-2')}>
      <div className={cx('form-grid-1')}>
        <Paragraph className={cx('form-label')} variant={'B2'} bold>
          <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '공고명' : 'Position'}</span>
        </Paragraph>
        <DropdownWithOnClick
          className={cx('select')}
          size={'lg'}
          options={[
            NORMAL_TYPE,
            ...positionList!.map(({ positionSn, positionName }: PositionList) => ({
              name: <span className={cx('option-label')}>{positionName}</span>,
              value: `${positionSn}`,
            })),
          ]}
          value={positionSn ? getFilterValue().value : NORMAL_TYPE.value}
          onChange={(value) => {
            if (!setPositionSn || !setPositionRecruitmentFieldGroupSn) return;

            if (value === 'null') {
              setPositionSn(null);
              setPositionRecruitmentFieldGroupSn(null);
            } else {
              setPositionSn(Number(value));
              setPositionRecruitmentFieldGroupSn(null);
            }
          }}
          disabled={isPreview}
        />
      </div>
      <div className={cx('form-grid-1')}>
        <Paragraph className={cx('form-label')} variant={'B2'} bold>
          <span className={cx('text', 'require')}>{languageType === LANGUAGE.KOR ? '채용 분야' : 'Job Category'}</span>
        </Paragraph>
        <div className={cx('select')}>
          <DropdownWithOnClick
            size={'lg'}
            options={(recruitmentFieldList ?? []).map(({ positionRecruitmentFieldGroupSn, recruitmentFieldName }) => ({
              name: <span className={cx('option-label')}>{recruitmentFieldName}</span>,
              value: `${positionRecruitmentFieldGroupSn}`,
            }))}
            value={positionRecruitmentFieldGroupSn ? `${positionRecruitmentFieldGroupSn}` : undefined}
            onChange={(value) => {
              setPositionRecruitmentFieldGroupSn && setPositionRecruitmentFieldGroupSn(Number(value));
              setIsRecruitmentFieldError && setIsRecruitmentFieldError(false);
            }}
            placeholder={languageType == LANGUAGE.KOR ? '채용 분야를 선택해주세요.' : 'Please select a job category.'}
            disabled={isPreview || !positionSn}
            error={isRecruitmentFieldError}
          />
          {isRecruitmentFieldError && <span className={cx('error-message')}>채용분야를 입력해주세요</span>}
        </div>
      </div>
    </div>
  );
};

export default QnAPositionJobflex;
