import style from './ModalQnAAgreement.module.scss';
import classnames from 'classnames/bind';
import Modal from '../../context/modal/Modal';
import ModalHeader from '../../context/modal/ModalHeader';
import { FC } from 'react';
import ModalFooter from '../../context/modal/ModalFooter';
import { ViewModeType } from '../../interface/common/ViewMode';
import Paragraph from '../../common/paragraph/Paragraph';
import { SOLUTION } from '../../constants/solution/solution.constant';
import { SOLUTION_TYPE } from '../../util/solution';

const cx = classnames.bind(style);

interface IProps {
  viewMode?: ViewModeType;
  setCheckAgreement: () => void;
  agreement?: {
    agreementLetterContents: string;
    isError: boolean;
  };
  show: boolean;
  close: () => void;
}
const ModalQnAAgreement: FC<IProps> = ({ viewMode, close, setCheckAgreement, show, agreement }) => {
  const JobdaContents = () => {
    return (
      <div className={cx('agree-content', viewMode)}>
        <h1 className={cx('h1')}>개인정보의 수집 및 이용에 관한 동의 관리</h1>
        <p className={cx('title-desc')}>
          &apos;게시판 운영주체&apos;로서 채용 관련 불만사항 접수 및 민원처리 등의 서비스를 제공하기 위하여 아래와 같은
          개인정보를 수집합니다.
        </p>
        <strong className={cx('table-title')}>1. 수집항목 및 이용목적</strong>
        <table className={cx('table')}>
          <tr>
            <th>수집구분</th>
            <th>수집 및 이용 목적</th>
            <th>처리하려는 개인정보 항목</th>
          </tr>
          <tr>
            <td>필수수집</td>
            <td className={cx('left')}>
              <ul>
                <li>입사지원서 본인 확인 및 개인식별</li>
                <li>불만사항 및 민원에 대한 처리</li>
                <li>무분별한 게시글 작성 방지</li>
                <li>채용관련 안내사항 전달</li>
                <li>통계자료 활용</li>
              </ul>
            </td>
            <td>이름, 전화번호, email</td>
          </tr>
        </table>

        <strong className={cx('table-title')} style={{ marginTop: '24px' }}>
          2. 개인정보 보유 및 이용기간
        </strong>
        <p className={cx('description-default')}>
          &apos;문의하기&apos;를 통해 입력한 개인정보는 채용과 관련된 질의분석 및 통계자료로 활용될 예정으로
          <br />
          <strong>최대 3년까지 보관되며, 보존기간이 만료되었을 경우 즉시 파기</strong>를 진행 합니다.
          <br /> 또한, 이용자가 <strong>‘게시판 운영주체’에게 본인의 개인정보 삭제 요청 시 지체 없이 삭제</strong> 를
          진행하고 그에 대한 결과를 통보해 드립니다.
        </p>
        <strong className={cx('table-title')} style={{ marginTop: '24px' }}>
          3. 정보주체의 권리와 그 사용 방법
        </strong>
        <p className={cx('description-default')}>
          지원자는 아래 개인정보 제공 등에 관해 동의하지 않을 권리가 있습니다. 다만, 문의하기를 통해 제공받는 정보는
          문의를 처리하기에 필수적인 항목으로 해당 정보를 제공받지 못할 경우 문의하기 기능의 이용이 제한됩니다.
        </p>
      </div>
    );
  };

  const JobflexContents = () => {
    if (!agreement || agreement.isError) {
      return (
        <div className={cx('error')}>
          <div className={cx('img')} />
          <strong className={cx('title')}>동의문을 불러오는데 실패했어요.</strong>
          <Paragraph variant={'B2'} className={cx('desc')}>
            잠시 후 다시 시도해주세요.
          </Paragraph>
        </div>
      );
    }

    return (
      <div className={cx('agree-content')}>
        <div dangerouslySetInnerHTML={{ __html: agreement.agreementLetterContents ?? '' }} />
      </div>
    );
  };

  const Contents = {
    [SOLUTION.JOBFLEX]: JobflexContents(),
    [SOLUTION.JOBDA]: JobdaContents(),
  };
  const handleClickAgree = () => {
    setCheckAgreement();
    close();
  };

  return (
    <Modal contentClassName={cx('modal-container', viewMode)} show={show} close={close} pressEnter={handleClickAgree}>
      <ModalHeader>개인 정보 수집 및 이용 동의</ModalHeader>
      {Contents[SOLUTION_TYPE]}
      <ModalFooter
        viewMode={viewMode}
        close={() => close()}
        btnTitle={'동의하고 시작하기'}
        cancelTitle={'닫기'}
        onClick={handleClickAgree}
      />
    </Modal>
  );
};

export default ModalQnAAgreement;
