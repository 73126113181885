'use client';

// Created by kdw0601 on 2022-12-28
import { useEffect, useRef, useState } from 'react';
import Icon, { IconTypes } from '../icon/Icon';
import { IListProps } from '../list/List';
import styles from '../dropdown/Dropdown.module.scss';
import classNames from 'classnames/bind';
import { ifIncludeRemoveElseAdd } from '../../util/array';
import ListPopoverWithOnClick from '../listPopoverWithOnClick/ListPopoverWithOnClick';

const cx = classNames.bind(styles);

interface IProps {
  size?: 'sm' | 'md' | 'lg';
  leftIcon?: IconTypes;
  placeholder?: string;
  selectedTextFormat?: string | ((count: number) => string);
  disabled?: boolean;
  scrollAfterNItems?: number;
  color?: 'default' | 'dark';
  menuColor?: 'default' | 'dark';
  error?: boolean;
}

interface IMultipleProps<T> extends Omit<IListProps<T>, 'onChange' | 'value' | 'scroll'> {
  onChange?: (val: T[]) => void;
  value?: T[];
}

const iconSize = {
  sm: 18,
  md: 24,
  lg: 32,
} as const;

const leftIconSize = {
  sm: 18,
  md: 24,
  lg: 24,
} as const;

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3624337847/Dropdown}
 */
const DropdownWithOnClick = <T,>(props: IProps & IListProps<T>) => {
  const {
    size = 'md',
    placeholder = '선택해주세요',
    options,
    value,
    leftIcon,
    disabled,
    onChange,
    variant,
    color = 'none',
    className,
    menuColor,
    scrollAfterNItems,
    error,
  } = props;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [popoverWidth, setPopoverWidth] = useState(0);
  useEffect(() => {
    setPopoverWidth(wrapperRef.current?.clientWidth ?? 0);
  }, [wrapperRef.current?.clientWidth, isOpen]);

  const selectedOption = options.find(({ value: optionValue }) => value === optionValue);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (disabled) e.stopPropagation();
  };

  return (
    <ListPopoverWithOnClick
      options={options}
      onChange={onChange}
      variant={variant}
      value={value}
      minWidth={popoverWidth}
      className={className}
      scrollAfterNItems={scrollAfterNItems}
      onChangeOpenState={setIsOpen}
      forDropdown
      color={menuColor}
      disabled={disabled}
    >
      <div
        className={cx('wrapper', size, color, { open: isOpen, disabled, error })}
        onMouseDown={handleMouseDown}
        ref={wrapperRef}
      >
        <div className={cx('selected-name', !selectedOption?.name && 'placeholder')}>
          {leftIcon && <Icon className={cx('left-icon')} name={leftIcon} size={leftIconSize[size]} />}
          <div className={cx('text')}>{selectedOption?.name ?? placeholder}</div>
        </div>
        <Icon className={cx('arrow-icon')} name={'arrow_bottom_line'} size={iconSize[size]} />
      </div>
    </ListPopoverWithOnClick>
  );
};

/**
 * 명세서: {@link https://midasitweb-jira.atlassian.net/wiki/spaces/ATS/pages/3624337847/Dropdown}
 */
DropdownWithOnClick.Multiple = function DropdownWithOnClickMultiple<T>(props: IProps & IMultipleProps<T>) {
  const {
    size = 'md',
    placeholder = '선택',
    options,
    value,
    leftIcon,
    disabled,
    onChange,
    variant = 'check',
    selectedTextFormat,
    color = 'none',
    className,
    scrollAfterNItems,
  } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [popoverWidth, setPopoverWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setPopoverWidth(wrapperRef.current?.clientWidth ?? 0);
  }, [wrapperRef.current?.clientWidth, isOpen]);

  const handleClickOption = (clickedValue: T) => {
    if (!props.onChange) return;
    const values = value ?? [];
    onChange?.(ifIncludeRemoveElseAdd(values, clickedValue));
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (disabled) e.stopPropagation();
  };

  const boxText = props.value?.length
    ? selectedTextFormat
      ? typeof selectedTextFormat === 'string'
        ? selectedTextFormat
        : selectedTextFormat(props.value.length)
      : `${props.value.length}개 선택됨`
    : placeholder;

  return (
    <ListPopoverWithOnClick
      options={options}
      onChange={handleClickOption}
      variant={variant}
      value={value}
      scrollAfterNItems={scrollAfterNItems}
      closeOnChange={!variant}
      minWidth={popoverWidth}
      onChangeOpenState={setIsOpen}
      forDropdown
      className={className}
      disabled={disabled}
    >
      <div
        className={cx('wrapper', size, disabled && 'disabled', className, color, isOpen && 'open')}
        onMouseDown={handleMouseDown}
        ref={wrapperRef}
      >
        <div className={cx('selected-name', !props.value?.length && 'placeholder')}>
          {leftIcon && <Icon name={leftIcon} size={leftIconSize[size]} />}
          <span className={cx('text')}>{boxText}</span>
        </div>
        <Icon className={cx('arrow-icon')} name={'arrow_bottom_line'} size={iconSize[size]} />
      </div>
    </ListPopoverWithOnClick>
  );
};

export default DropdownWithOnClick;
